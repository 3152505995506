import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const qrApi = createApi({
  reducerPath: "qrApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://qr-menu-project.onrender.com/api`,
    credentials: "include",
  }),
  tagTypes: ["Qr"],
  endpoints: (builder) => ({
    getQr: builder.query({
      query: (business) => `/${business}/qr`,
      providesTags: ["Qr"],
    }),
    createQr: builder.mutation({
      query(body) {
        return {
          url: "/qr",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Qr"],
    }),
    deleteQr: builder.mutation({
      query(body) {
        return {
          url: "/qr",
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["Qr"],
    }),
  }),
});

export const { useGetQrQuery, useCreateQrMutation, useDeleteQrMutation } =
  qrApi;
