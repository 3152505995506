import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MetaData from "../layouts/MetaData";
import { useUpdateContactMutation } from "../../redux/api/businessApi";

const ContactUpdate = ({ business }) => {
  const [data, setData] = useState("");
  const [update, { isLoading, isSuccess, error }] = useUpdateContactMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  }

  const onSubmit = (e) => {
    update(data);
  };

  useEffect(() => {
    if (business) {
      setData({
        phone: business?.contact?.phone,
        email: business?.contact?.email,
        address: business?.contact?.address,
        id: business?._id,
      });
    }
    if (error) {
      toast.error(error.data.message);
    }
    if (isSuccess) {
      toast.success("İletişim bilgileri güncellendi");
    }
  }, [business, isSuccess, error]);
  

  return (
    <div className="bg-white mx-3 rounded-md">
      <MetaData text={"İletişim Bilgilerini Güncelle"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        İletişim Bilgilerini Güncelle
      </h1>
      <div className="p-2">
        <div className="mb-3">
          <label htmlFor="phone" className="block text-lg font-semibold">
            Telefon
          </label>
          <input
            type="tel"
            id="phone"
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Telefon Numarası"
            value={data?.phone}
            onChange={onChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="block text-lg font-semibold">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Email Adresi"
            value={data?.email}
            onChange={onChange}
          />
        </div>

        <div className="mb-5">
          <label htmlFor="address" className="block text-lg font-semibold">
            Adres
          </label>
          <textarea
            type="text"
            id="address"
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Adres"
            value={data?.address}
            onChange={onChange}
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {isLoading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUpdate;
