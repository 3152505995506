import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductOfCategoryQuery } from "../redux/api/productApi";
import ProductItem from "../components/products/ProductItem";
import Loader from "../components/layouts/Loader";
import MetaData from "../components/layouts/MetaData";
import Footer from "../components/layouts/Footer";

const Categories = () => {
  const [keyword, setKeyword] = useState("");
  const params = { keyword };

  const navigate = useNavigate();
  const { business } = useParams();
  const { category } = useParams();

  const { data: products, isLoading } = useGetProductOfCategoryQuery({
    slug: category,
    business,
    params,
  });
  const theme = JSON.parse(localStorage.getItem("theme"));

  useEffect(() => {
    if (keyword) {
      navigate(`/${business}/kategori/${category}?keyword=${keyword}`);
    }
  }, [keyword]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="pt-20 max-sm:px-10 sm:px-16 lg:px-24 xl:px-32 flex flex-col gap-4 min-h-screen pb-32" style={{backgroundColor: theme?.body?.backgroundColor || "#fff"}}>
        <MetaData
          text={
            products?.product[0]?.category?.name !== undefined
              ? products?.product[0]?.category?.name
              : category
          }
        />
        <div className="flex justify-between sm:items-center sm:px-5 max-sm:flex-col">
          <p className="text-lg flex gap-1">
            Kategori:
            <span className="" style={{color: theme?.body?.textColor || "#f97316"}}>
              {products?.product[0]?.category?.name !== undefined
                ? products?.product[0]?.category?.name
                : category}
            </span>
          </p>
          <div className="flex items-center gap-3 max-md:hidden">
            <h1 className="cormorant-title text-xl">Arama: </h1>
            <input
              type="text"
              name="search"
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Bir kelime giriniz"
              className="border focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 rounded-md py-2 w-full"
            />
          </div>
          <p>
            Toplam Ürün Sayısı:{" "}
            <span className="" style={{borderColor: theme?.body?.textColor || "#f97316"}}>{products?.product?.length}</span>
          </p>
        </div>
        <div className="">
          <div className="flex items-center gap-3 md:hidden px-5 border-2 rounded-md" style={{borderColor: theme?.body?.textColor || "#f97316"}}>
            <h1 className="cormorant-title text-xl">Arama: </h1>
            <input
              type="text"
              name="search"
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Bir kelime giriniz"
              className="border-none rounded-md py-2 w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {products?.product?.map((product) => (
            <ProductItem product={product} />
          ))}
        </div>
        {products?.product?.length === 0 && (
          <div className="flex justify-center items-center">
            <div className="text-center">
              <h1 className="text-4xl font-bold">Ürün bulunamadı.</h1>
              <p>Bu kelime(ler) ile kayıtlı bir ürün bulunamadı. ({keyword})</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Categories;
