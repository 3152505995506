import React, { useEffect, useState } from "react";
import MetaData from "../layouts/MetaData";
import { useCreateBusinessMutation } from "../../redux/api/businessApi";
import { toast } from "react-toastify";

const CreateBusiness = () => {
  const [create, { isLoading, isSuccess, error }] = useCreateBusinessMutation();

    const [data, setData] = useState({
        name: "",
        address: "",
        phone: "",
        email: "",
    })
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const onSubmit = (e) => {
      e.preventDefault();
        create(data);
    };

    useEffect(() => {
        if (error) {
            toast.error(error.data.message);
        }
        if (isSuccess) {
            toast.success("İşletme oluşturuldu");
        }
    }, [isSuccess, error]);
  return (
    <div className="bg-white mx-3 rounded-md">
      <MetaData text={"İşletme Oluştur"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        İşletme Oluştur
      </h1>

      <div className="p-3">
        <form>
        <div className="mb-3">
          <label htmlFor="name" className="block text-lg font-semibold">
            İşletme Adı
          </label>
          <input
            type="text"
            name="name"
            onChange={onChange}
            required
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="İşletme Adı"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="block text-lg font-semibold">
            İşletme Adresi
          </label>
          <input
            type="text"
            name="address"
            onChange={onChange}
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="İşletme Adresi"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="block text-lg font-semibold">
            İşletme Telefon Numarası
          </label>
          <input
            type="tel"
            name="phone"
            required
            onChange={onChange}
            pattern="[0-9]{10}"
            maxLength={11}
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="İşletme Telefon Numarası"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="block text-lg font-semibold">
            İşletme E-Posta Adresi
          </label>
          <input
            type="email"
            name="email"
            onChange={onChange}
            className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="İşletme E-Posta Adresi"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {isLoading ? "Oluşturuluyor..." : "Oluştur"}
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBusiness;
