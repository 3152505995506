import "./App.css";
import Login from "./components/auth/Login";
import Header from "./components/layouts/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/layouts/Footer";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import GetAllProducts from "./components/admin/products/GetAllProducts";
import GetAllCategories from "./components/admin/categories/GetAllCategories";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateProduct from "./components/admin/products/CreateProduct";
import EditProduct from "./components/admin/products/EditProduct";
import CreateCategory from "./components/admin/categories/CreateCategory";
import EditCategory from "./components/admin/categories/EditCategory";
import GetAllUsers from "./components/admin/users/GetAllUsers";
import CreateUser from "./components/admin/users/CreateUser";
import UpdateUser from "./components/admin/users/UpdateUser";
import Qr from "./components/admin/qr/Qr";
import ProductDetails from "./pages/ProductDetails";
import Products from "./pages/Products";
import Categories from "./pages/Categories";
import UpdateLogo from "./components/business/UpdateLogo";
import ContactUpdate from "./components/business/ContactUpdate";
import CreateBusiness from './components/business/CreateBusiness';
import GetAllBusiness from "./components/business/GetAllBusiness";
import Cart from "./components/products/Cart";
import GetBusinessUsers from "./components/business/GetBusinessUsers";
import Panel from "./components/admin/Panel";
import UpdateTheme from "./components/business/UpdateTheme";

function App() {
  
  return (
    <Router>
      <Header />
      <ToastContainer position="top-right" />
      <Routes>
        <Route path="/:business" element={<Home />} />
        <Route path="/panel" element={<Panel />} />
        {/* <Route path="/:business/sepet" element={<Cart />} /> */}
        <Route path="/:business/urunler" element={<Products />} />
        <Route path="/:business/urun/:category/:id" element={<ProductDetails />} />
        <Route path="/:business/kategori/:category" element={<Categories />} />
        <Route path="/giris" element={<Login />} />

        {/* ADMIN & BUSINESS */}
        
        <Route path="/admin/kullanicilar" element={<ProtectedRoute><GetAllUsers /></ProtectedRoute>} />
        <Route path="/admin/kullanici/:id" element={<ProtectedRoute><UpdateUser /></ProtectedRoute>} />
        <Route path="/admin/isletmeler" element={<ProtectedRoute><GetAllBusiness /></ProtectedRoute>} />

        <Route path="/:business">
          <Route path="admin">
            <Route path="urunler" element={<ProtectedRoute><GetAllProducts /></ProtectedRoute>} />
            <Route path="urun/:id" element={<ProtectedRoute><EditProduct /></ProtectedRoute>} />
            <Route path="urun-ekle" element={<ProtectedRoute><CreateProduct /></ProtectedRoute>} />

            <Route path="kategoriler" element={<ProtectedRoute><GetAllCategories /></ProtectedRoute>} />
            <Route path="kategori-ekle" element={<ProtectedRoute><CreateCategory /></ProtectedRoute>} />
            <Route path="kategori/:id" element={<ProtectedRoute><EditCategory /></ProtectedRoute>} />

            <Route path="kullanici-ekle" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />

            <Route path="qr" element={<ProtectedRoute><Qr /></ProtectedRoute>} />

            <Route path="logo-guncelle" element={<ProtectedRoute><UpdateLogo /></ProtectedRoute>} />
            <Route path="tema-guncelle" element={<ProtectedRoute><UpdateTheme /></ProtectedRoute>} />
            <Route path="iletisim-guncelle" element={<ProtectedRoute><ContactUpdate /></ProtectedRoute>} />
            <Route path="isletme-olustur" element={<ProtectedRoute><CreateBusiness /></ProtectedRoute>} />
            <Route path="kullanicilar" element={<ProtectedRoute><GetBusinessUsers /></ProtectedRoute>} />
          </Route>
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;
