import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateUserMutation } from "../../../redux/api/userApi";
import MetaData from "../../layouts/MetaData";
import { useSelector } from "react-redux";
import { useGetBusinessesQuery } from "../../../redux/api/businessApi";

const CreateUser = () => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "",
    business: "",
  });
  const { firstname, lastname, email, password, role, business } = data;

  const [createUser, { error, isSuccess, isLoading }] = useCreateUserMutation();
  const { data: businessData } = useGetBusinessesQuery();
  const { user } = useSelector((state) => state.auth);

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user?.data?.role === "business") {
        return await createUser({
          firstname,
          lastname,
          email,
          password,
          role: "business",
          business: user?.data?.business?._id,
        });
      }
      if (user?.data?.role === "admin") {
        await createUser({
          firstname,
          lastname,
          email,
          password,
          role,
          business,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kayıt oluşturuldu.");
    }
  }, [error, isSuccess]);

  return (
    <div className="mx-3 bg-white rounded-md">
      <MetaData text={"Kullanıcı Oluştur"} />

      <div className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        <h1 className="text-4xl font-bold cormorant-title">Kullanıcı Ekle</h1>
      </div>
      <form method="post" onSubmit={onSubmit} className="p-3">
        <div className="flex flex-col mb-6">
          <label
            htmlFor="firstname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ad
          </label>
          <input
            type="text"
            name="firstname"
            placeholder="Adınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="lastname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Soyad
          </label>
          <input
            type="text"
            name="lastname"
            placeholder="Soyadınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="email"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            E-Posta
          </label>
          <input
            type="email"
            name="email"
            placeholder="E-Postanızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="password"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Parola
          </label>
          <input
            type="password"
            name="password"
            placeholder="Parolanızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        {user?.data?.role === "admin" && (
          <>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="role"
                className="mb-2 text-sm font-medium text-gray-700"
              >
                Rol Seçiniz
              </label>
              <select
                value={`${role}`}
                className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                name="role"
                onChange={(e) => onChange(e)}
              >
                <option value="">Seçin</option>
                <option value="admin">admin</option>
                <option value="business">business</option>
                <option value="user">kullanıcı</option>
              </select>
            </div>
            {role === "business" && (
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="business"
                  className="mb-2 text-sm font-medium text-gray-700"
                >
                  İşletme Seçiniz
                </label>
                <select
                  value={data?.business}
                  className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                  name="business"
                  onChange={(e) => onChange(e)}
                >
                  <option value="">Seçin</option>

                  {businessData?.businesses?.map((b) => (
                    <option value={b?._id}>{b.name}</option>
                  ))}
                </select>
              </div>
            )}
          </>
        )}

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Ekleniyor..." : "Oluştur"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
