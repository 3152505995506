const images = [
  "/images/pull-me-kirmizi-2.jpg",
  "/images/clothilde-1.jpg",
  "/images/lotus-cup-4.jpg",
  "/images/odeon-1.jpg",
  "/images/pull-me-lotus-3.jpg",
];

const Loader = () => {
  return (
    <div>
      <CutoutTextLoader
        height="100vh"
        background="white"
        imgUrl={images[Math.floor(Math.random() * images?.length)]}
      />
    </div>
  );
};

const CutoutTextLoader = ({ height, background, imgUrl }) => {
  return (
    <div className="relative overflow-hidden" style={{ height }}>
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <div
        style={{ background }}
        className="absolute inset-0 animate-pulse z-10 overflow-hidden"
      />
      <span
        className="font-black absolute inset-0 z-20 text-center bg-clip-text text-transparent pointer-events-none max-md:text-4xl text-8xl"
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          lineHeight: height,
        }}
      >
        Lezzet Yükleniyor
      </span>
    </div>
  );
};

export default Loader;