import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Squash } from "hamburger-react";
import useClickOutside from "../../hook/useClickOutside";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import { useGetMeQuery } from "../../redux/api/userApi";
import { useGetBusinessWithSlugQuery } from "../../redux/api/businessApi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [logo, setLogo] = useState("/logo.png");
  const [businessName, setBusinessName] = useState("");
  const [category, setCategory] = useState([]);
  const [showSubbar, setShowSubbar] = useState({
    dropdownNumber: null,
    show: false,
    hidden: true,
  });
  const { data: categories } = useGetCategoriesQuery({ slug: businessName });

  const navigate = useNavigate();

  const theme = JSON.parse(localStorage.getItem("theme"));

  const { loading, user, isAuthenticated } = useSelector((state) => state.auth);
  const { data } = useGetMeQuery();
  const { data: business } = useGetBusinessWithSlugQuery(businessName);

  const ref = useClickOutside(() => setShowSidebar(false));

  const sidebarItems = [
    {
      name: "Anasayfa",
      url: `/${businessName}`,
    },
    {
      name: "Ürünler",
      url: `/${businessName}/urunler`,
      dropdown: false,
    },
    {
      name: "Kategoriler",
      dropdown: true,
      dropdownNumber: 2,
      dropdownItems: category,
    },
  ];

  const clickRef = useClickOutside(() => setShowSidebar(false));

  useEffect(() => {
    if (categories) {
      categories?.categories?.map((cate) =>
        setCategory((oldArray) => [
          ...oldArray,
          { name: cate?.name, url: `/${businessName}/kategori/${cate?.slug}` },
        ])
      );
    }

    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }

    if (business?.business?.logo?.url) {
      setLogo(business?.business?.logo?.url);
    }
  }, [categories, business]);

  return (
    <div className="mb-18" ref={clickRef}>
      {/* Header */}
      <header
        className="h-20 shadow-md w-full fixed z-50 top-0 left-0 flex justify-center items-center px-10 max-sm:px-5"
        style={{ backgroundColor: theme?.header?.backgroundColor || "#fff" }}
      >
        <img
          src={logo || "e-waiter-logo.png"}
          alt="logo"
          className="h-20 w-full object-contain cursor-pointer"
          onClick={() => navigate(`/${businessName}`)}
        />
        <div className="absolute top-0 max-sm:right-5 sm:right-10 flex justify-center items-center h-full">
          <Squash
            color={theme?.header?.buttonColor || "black"}
            onToggle={() => setShowSidebar(!showSidebar)}
            toggled={showSidebar}
          />
        </div>
      </header>

      {/* Sidebar */}
      <aside
        className={`bg-[#FCF8ED] shadow-2xl fixed right-0 top-0 h-screen transition-all duration-500 pt-20 ${
          showSidebar ? "w-96 z-40" : "w-0"
        }`}
      >
        <div className="flex justify-center py-5 border-b">
          <a href={`/${businessName}`}>
            <img
              src={logo || "e-waiter-logo.png"}
              alt="logo"
              className="h-32 w-full object-cover"
            />
          </a>
        </div>

        <nav className="mt-5 select-none">
          {sidebarItems?.map((item) => (
            <ul key={item.name}>
              <li
                className="flex justify-between cursor-pointer px-4 mt-2"
                onClick={() =>
                  item.dropdown &&
                  setShowSubbar((prev) => ({
                    dropdownNumber: item.dropdownNumber,
                    show: !prev.show,
                    hidden: !prev.hidden,
                  }))
                }
              >
                <a
                  className={`text-4xl transition-colors ${
                    showSubbar?.dropdownNumber === item?.dropdownNumber &&
                    !showSubbar?.hidden &&
                    showSubbar?.show &&
                    "text-orange-500"
                  }`}
                  onClick={() => navigate(item?.url)}
                >
                  {item?.name}
                </a>
                {item.dropdown && (
                  <i
                    className={`ri-arrow-right-line text-2xl transition-transform transform ${
                      showSubbar?.dropdownNumber === item?.dropdownNumber &&
                      !showSubbar?.hidden &&
                      showSubbar?.show &&
                      "rotate-90 text-orange-500"
                    }`}
                  />
                )}
              </li>

              {/* Dropdown Items */}
              {item.dropdown && (
                <ul
                  className={`overflow-auto transition-all duration-300 ${
                    showSubbar?.show &&
                    showSubbar?.dropdownNumber === item?.dropdownNumber &&
                    !showSubbar?.hidden
                      ? "max-h-80"
                      : "max-h-0"
                  }`}
                >
                  {item?.dropdownItems?.map((dItem) => (
                    <li
                      key={dItem.name}
                      className="cursor-pointer py-2 px-6 text-2xl hover:text-orange-500"
                      onClick={() => navigate(dItem?.url)}
                    >
                      {dItem?.name}
                    </li>
                  ))}
                </ul>
              )}
            </ul>
          ))}

          {/* Admin Panel Link */}
          {isAuthenticated &&
            ["admin", "business"].includes(user?.data?.role) && (
              <ul>
                <li className="cursor-pointer px-4 mt-2">
                  <a
                    href={`/${businessName}/admin/urunler`}
                    className="text-4xl"
                  >
                    Admin Sayfası
                  </a>
                </li>
              </ul>
            )}
        </nav>
      </aside>
    </div>
  );
};

export default Header;
