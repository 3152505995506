import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticated, setLoading, setUser } from "../features/userSlice";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://qr-menu-project.onrender.com/api`,
    credentials: "include",
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => `/me`,
      transformResponse: (result) => result.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setIsAuthenticated(false));
          dispatch(setLoading(true));
        }
      },
    }),
    getUsers: builder.query({
      query: () => `/users`,
      providesTags: ["Users"],
    }),
    getBusinessUsers: builder.query({
      query: (business) => `/users/${business}`,
      providesTags: ["Users"],
    }),
    getUser: builder.query({
      query: (id) => `/user/${id}`,
      providesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query(id) {
        return {
          url: `/user/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation({
      query(body) {
        return {
          url: `/register`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query(body) {
        return {
          url: `/user/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useGetBusinessUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
} = userApi;
