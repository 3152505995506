import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderComp = () => {
  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };
  return (
    <div className="slider-container w-full -mb-2 overflow-hidden max-md:max-h-[500px]  object-cover">
      <Slider {...settings} className="rounded-xl">
        <img
          src="/images/slider-img-3.png"
          className="min-h-screen max-h-screen  w-full"
          alt=""
        />
        <div className="">
          <img
            src="/images/slider-img-4.png"
            className=" min-h-screen max-h-screen  w-full"
            alt=""
          />
        </div>
      </Slider>
    </div>
  );
};

export default SliderComp;
