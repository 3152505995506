import React from "react";
import { Table } from "antd";


const TableList = ({columns, data}) => (
  <div className="flex justify-center items-center w-full">
    <div className="max-sm:w-72 w-11/12 border rounded-lg">
    <Table columns={columns} dataSource={data} scroll={{x: 100}}  />
  </div>
  </div>
);
export default TableList;
