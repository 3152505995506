import React, { useEffect, useState } from "react";
import { FaHamburger, FaUserPlus } from "react-icons/fa";
import { FaArrowsLeftRightToLine } from "react-icons/fa6";
import { TbCategory } from "react-icons/tb";
import { BsFillBagPlusFill, BsShop } from "react-icons/bs";
import { TbCategoryPlus } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { BsQrCode } from "react-icons/bs";
import { GiExitDoor } from "react-icons/gi";
import { useLazyLogoutQuery } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LuImagePlus } from "react-icons/lu";
import { TiBusinessCard } from "react-icons/ti";
import { MdAddBusiness } from "react-icons/md";
import { useGetBusinessWithSlugQuery } from "../../redux/api/businessApi";
import { useSelector } from "react-redux";
import { IoIosColorPalette } from "react-icons/io";

const Sidebar = ({ setShowSidebar, showSidebar }) => {
  const [activeUrl, setActiveUrl] = useState("");
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const { user } = useSelector((state) => state.auth);

  const [logout, { isSuccess }] = useLazyLogoutQuery();
  const { data: business } = useGetBusinessWithSlugQuery(businessName);

  const sidebarItems = [
    {
      text: "Ürünler",
      url: "/admin/urunler",
      icon: <FaHamburger color="#64748b" size={28} />,
    },
    {
      text: "Ürün Ekle",
      url: "/admin/urun-ekle",
      icon: <BsFillBagPlusFill color="#64748b" size={28} />,
    },
    {
      text: "Kategoriler",
      url: "/admin/kategoriler",
      icon: <TbCategory color="#64748b" size={28} />,
    },
    {
      text: "Kategori Ekle",
      url: "/admin/kategori-ekle",
      icon: <TbCategoryPlus color="#64748b" size={28} />,
    },
    {
      text: "Qr",
      url: "/admin/qr",
      icon: <BsQrCode color="#64748b" size={32} />,
    },
    {
      text: "Kullanıcı Ekle",
      url: "/admin/kullanici-ekle",
      icon: <FaUserPlus color="#64748b" size={32} />,
    },
  ];

  const logoutHandler = () => {
    logout();
    window.location.href = "/giris";
  };
  const routeHandler = (path) => navigate(path);

  useEffect(() => {
    setActiveUrl(pathname);

    if (isSuccess) {
      toast.success("Çıkış Yapıldı.");
    }

    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
  }, [activeUrl, isSuccess, pathname]);

  return (
    <div className="max-h-[calc(100vh-260px)] over h-full flex flex-col justify-between">
      <div className="">
        <div className="flex justify-center border-b pb-5 mb-5">
          <img
            src={
              business?.business?.logo?.url
                ? business?.business?.logo?.url
                : "/images/logo.png"
            }
            alt="logo"
            className="h-16 w-32 px-2 object-fill"
          />
        </div>
        {/* MENU-ITMELERI */}
        <div className="max-h-[calc(100vh-260px)] overflow-y-auto sidebar">
          {sidebarItems?.map((item) => (
            <div
              className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md ${
                `/${businessName}${item.url}` === activeUrl && "bg-orange-200"
              } cursor-pointer`}
              onClick={() => routeHandler(`/${businessName}${item.url}`)}
            >
              {item.icon}
              <span className="font-semibold text-lg text-[#64748b]">
                {showSidebar && item.text}
              </span>
            </div>
          ))}
          {/* sirket-itemleri */}
          {(user?.data?.role === "business" ||
            user?.data?.role === "admin") && (
            <div className="">
              {/* business users */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/${businessName}/admin/kullanicilar` === activeUrl &&
                  "bg-orange-200"
                }`}
                onClick={() =>
                  routeHandler(`/${businessName}/admin/kullanicilar`)
                }
              >
                <FaUser color="#64748b" size={32} />
                <span className="font-semibold text-lg text-[#64748b]">
                  {showSidebar && "Kullanıcılar"}
                </span>
              </div>
              {/* update-logo */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/${businessName}/admin/logo-guncelle` === activeUrl &&
                  "bg-orange-200"
                }`}
                onClick={() =>
                  routeHandler(`/${businessName}/admin/logo-guncelle`)
                }
              >
                <LuImagePlus color="#64748b" size={32} />
                <span className="font-semibold text-xl text-[#64748b]">
                  {showSidebar && "Logo Güncelle"}
                </span>
              </div>
              {/* update-contact */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/${businessName}/admin/iletisim-guncelle` === activeUrl &&
                  "bg-orange-200"
                }`}
                onClick={() =>
                  routeHandler(`/${businessName}/admin/iletisim-guncelle`)
                }
              >
                <TiBusinessCard color="#64748b" size={32} />
                <span className="font-semibold text-xl text-[#64748b]">
                  {showSidebar && "İletişim Bilgilerini Güncelle"}
                </span>
              </div>
              {/* update-contact */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/${businessName}/admin/tema-guncelle` === activeUrl &&
                  "bg-orange-200"
                }`}
                onClick={() =>
                  routeHandler(`/${businessName}/admin/tema-guncelle`)
                }
              >
                <IoIosColorPalette color="#64748b" size={32} />
                <span className="font-semibold text-xl text-[#64748b]">
                  {showSidebar && "Tema Güncelle"}
                </span>
              </div>
            </div>
          )}

          {/* admin-itemleri */}
          {user?.data?.role === "admin" && (
            <div className="">
              {/* all-users */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/admin/kullanicilar` === activeUrl && "bg-orange-200"
                }`}
                onClick={() => routeHandler(`/admin/kullanicilar`)}
              >
                <FaUser color="#64748b" size={32} />
                <span className="font-semibold text-md text-[#64748b]">
                  {showSidebar && "Kullanıcılar"}
                </span>
              </div>
              {/* all-business */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/admin/isletmeler` === activeUrl && "bg-orange-200"
                }`}
                onClick={() => routeHandler(`/admin/isletmeler`)}
              >
                <BsShop color="#64748b" size={32} />
                <span className="font-semibold text-md text-[#64748b]">
                  {showSidebar && "İşletmeler"}
                </span>
              </div>
              {/* create-business */}
              <div
                className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md cursor-pointer ${
                  `/${businessName}/admin/isletme-olustur` === activeUrl &&
                  "bg-orange-200"
                }`}
                onClick={() =>
                  routeHandler(`/${businessName}/admin/isletme-olustur`)
                }
              >
                <MdAddBusiness color="#64748b" size={32} />
                <span className="font-semibold text-xl text-[#64748b]">
                  {showSidebar && "İşletme Oluştur"}
                </span>
              </div>
            </div>
          )}
          {/* cikis butonu */}
          <div
            className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md bg-red-200 cursor-pointer my-1`}
            onClick={logoutHandler}
          >
            <GiExitDoor color="#64748b" size={32} />
            <span className="font-semibold text-lg text-[#64748b]">
              {showSidebar && "Çıkış"}
            </span>
          </div>
        </div>
      </div>
      {/* KAPAT-AC */}
      <div
        className="flex flex-col w-full items-center justify-center border-t-2 py-2 cursor-pointer"
        onClick={() => setShowSidebar(showSidebar ? false : true)}
      >
        <FaArrowsLeftRightToLine color="orange" size={28} />
        <p>{showSidebar ? "KAPAT" : "AÇ"}</p>
      </div>
    </div>
  );
};

export default Sidebar;
