import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../layouts/Button";

const ProductItem = ({ product }) => {
  const [businessName, setBusinessName] = useState("");
  const navigate = useNavigate();
  const onClick = (slug, id) => {
    window.scrollTo(0, 0);
    navigate(`/${businessName}/urun/${slug}/${id}`)
  };

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
  }, []);
  return (
    <div className="cursor-pointer p-1 rounded-lg mx-2 group">
      <div className="relative ">
        <div className="max-h-[400px]  h-full w-full">
          <img
            src={product?.images[0]?.url ? product?.images[0]?.url : "/images/default_product.png"}
            alt={product?.name}
            className="rounded-t-lg max-h-[300px] min-h-[300px] h-full w-full"
          />
        </div>
        <div
          className="absolute opacity-0 group-hover:opacity-100 transition-all rounded-t-lg bg-black bg-opacity-15 flex justify-center items-center inset-0"
          onClick={() => onClick(product?.category?.slug, product?._id)}
        >
          <button className="font-bold cormorant-title text-3xl text-white">
            ÜRÜNE GİT
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center mt-5 ">
        <h1
          className="text-3xl"
          onClick={() => onClick(product?.category?.slug, product?._id)}
        >
          {product?.name}
        </h1>
        <p
          className="text-orange-500"
          onClick={() => onClick(product?.category?.slug, product?._id)}
        >
          {product?.price}₺
        </p>
      </div>
      <div className="mt-5">
        <p className="min-h-20 line-clamp-3">{product?.subtitle}</p>
      </div>
      <div className="">
        <Button
          className="text-center w-full py-5 mt-3 bg-orange-500 group-hover:bg-orange-600 transition-all text-white rounded-lg font-semibold"
          onClick={() => onClick(product?.category?.slug, product?._id)}
          text={"Ürüne Git"}
          />
          
      </div>
    </div>
  );
};

export default ProductItem;
