import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
};

export const cartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const isItemExist = state.cart.find((i) => i._id == item._id);

      if (isItemExist) {
        isItemExist.quantity += 1;
      } else {
        state.cart = [...state.cart, item];
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    clearToCart: (state, action) => {
      state.cart = [];
      localStorage.removeItem("cart");
    },
    updateToItem: (state, action) => {
      const updatedItem = action.payload;
    
      // Sepette aynı `id`ye sahip olmayan ürünleri filtrele
      const filteredItems = state.cart.filter((item) => item._id !== updatedItem._id);
    
      // Güncellenmiş öğeyi sepetin içine ekle
      state.cart = [...filteredItems, updatedItem];
    
      // Güncellenmiş sepeti localStorage'a kaydet
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    
  },
});
export default cartSlice.reducer;
export const { addToCart, clearToCart, updateToItem } = cartSlice.actions;
