import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateCategoryMutation,
  useGetCategoryDetailsQuery,
} from "../../../redux/api/categoryApi";
import { toast } from "react-toastify";
import MetaData from "../../layouts/MetaData";

const CreateCategory = ({business}) => {
  const [data, setData] = useState({ name: "" });
  const { name } = data;
  const [image, setImage] = useState([]);
  const [previewImages, setPreviewImages] = useState({});

  const { id } = useParams();
  const { data: category } = useGetCategoryDetailsQuery(id);
  const [createCategory, { error, isSuccess, isLoading }] = useCreateCategoryMutation();

  const navigate = useNavigate();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImages({ url: reader.result, public_id: reader?.result });
        setImage({ url: reader.result, public_id: reader?.result });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCategory({ name, image, business: business?.slug });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kategori oluşturuldu.");
      navigate(`/${business?.slug}/admin/kategoriler`);
    }
  }, [error, isSuccess]);

  return (
    <div className="mx-3 bg-white rounded-md">
      <MetaData text={"Kategori Ekle"} />

      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        Kategori Ekle
      </h1>
      <form method="post" onSubmit={onSubmit} className="p-3">
        <div className="flex flex-col mb-6">
          <label
            htmlFor="name"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Adı
          </label>
          <input
            type="text"
            name="name"
            placeholder="Ürün adı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="image"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Resmi
          </label>
          <input
            type="file"
            name="image"
            onChange={(e) => onChangeImage(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>
        <img
          src={previewImages?.url}
          className={`h-16 w-20 rounded-md ${
            previewImages?.url == undefined && "hidden"
          }`}
        />

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Ekleniyor..." : "Oluştur"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;
