import React, { useEffect, useState } from "react";
import { useCreateProductMutation } from "../../../redux/api/productApi";
import { useGetCategoriesQuery } from "../../../redux/api/categoryApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MetaData from "../../layouts/MetaData";

const CreateProduct = ({business}) => {
  const [data, setData] = useState({
    name: "",
    desc: "",
    price: "",
    images: [],
    category: "",
    subtitle: "",
  });
  const [images, setImages] = useState([]);
  const [businessName, setBusinessName] = useState("");

  const { name, desc, price, category, subtitle } = data;
  const [create, { error: productError, isSuccess: productSuccess, isLoading }] =
    useCreateProductMutation();
  const { data: categories, error, isSuccess } = useGetCategoriesQuery({slug: business?.slug});
  const navigate = useNavigate();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeImages = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((oldArray) => [...oldArray, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await create({
        name,
        desc,
        price,
        category,
        images,
        subtitle,
        business: business?.slug
      });
    } catch (error) {
      console.error("Yükleme Hatası:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
    if (productError) {
      toast.error(productError?.data?.message);
    }
    if (productSuccess) {
      navigate(`/${businessName}/admin/urunler`);
      toast.success("Ürün oluşturuldu.");
    }
  }, [productError, productSuccess]);

  return (
    <div className="mx-3 bg-white  rounded-md">
      <MetaData text={"Ürün Ekle"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4 w-full">
        Ürün Ekle
      </h1>
      <form method="post" onSubmit={onSubmit} className="p-3">
        <div className="flex flex-col mb-6">
          <label
            htmlFor="name"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Adı
          </label>
          <input
            type="text"
            name="name"
            placeholder="Ürün adı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="subtitle"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Altbaşlığı
          </label>
          <input
            type="text"
            name="subtitle"
            placeholder="Ürün altbaşlığı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="desc"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Açıklaması
          </label>
          <input
            type="text"
            name="desc"
            placeholder="Ürün açıklaması girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="price"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Fiyatı
          </label>
          <input
            type="number"
            name="price"
            placeholder="Ürün fiyatı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="images"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Resmi
          </label>
          <input
            type="file"
            multiple
            name="images"
            onChange={(e) => onChangeImages(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="category"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Kategori Seçiniz
          </label>
          <select
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
            name="category"
            onChange={(e) => onChange(e)}
          >
            <option value="">Seçin</option>
            {categories?.categories?.map((cate) => (
              <option value={cate._id}>{cate.name}</option>
            ))}
          </select>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Ekleniyor..." : "Oluştur"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
