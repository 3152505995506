import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Popconfirm, Space } from "antd";
import TableList from "../../layouts/TableList";
import {
  useDeleteProductMutation,
  useGetProductsOfBusinessQuery,
} from "../../../redux/api/productApi";
import MetaData from "../../layouts/MetaData";

const GetAllProducts = () => {
  const [businessName, setBusinessName] = useState("");

  const { business } = useParams();
  const navigate = useNavigate();

  const { data: products } = useGetProductsOfBusinessQuery({ slug: business });
  const [deleteProduct, { error, isSuccess }] = useDeleteProductMutation();

  const routeHandler = (path) => navigate(path);
  const columns = [
    {
      title: "Ürün Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ürün Fiyatı",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            color="primary"
            onClick={() =>
              routeHandler(
                `/${businessName}/urun/${record?.category?.slug}/${record?._id}`
              )
            }
          >
            Ürüne Git
          </Button>
          <Button
            color="primary"
            onClick={() =>
              routeHandler(`/${businessName}/admin/urun/${record?._id}`)
            }
          >
            Güncelle
          </Button>
          <Popconfirm
            title="Ürünü Sil"
            description="Bu ürünü silmek istediğine emin misin?"
            onConfirm={() => deleteProduct(record?._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Ürün Başarıyla Silindi");
    }
  }, [error, isSuccess]);

  return (
    <div className="bg-white mx-3 rounded-lg pb-3">
      <MetaData text={"Tüm Ürünler"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        ÜRÜNLER
      </h1>
      <div className="">
        <TableList columns={columns} data={products?.products} />
      </div>
    </div>
  );
};

export default GetAllProducts;
