import React, { useEffect } from "react";
import MetaData from "../layouts/MetaData";
import TableList from "../layouts/TableList";
import { toast } from "react-toastify";
import { useDeleteUserMutation, useGetBusinessUsersQuery } from "../../redux/api/userApi";
import { Button, Popconfirm, Space } from "antd";
import Loader from "../layouts/Loader";

const GetBusinessUsers = ({business}) => {
  const { data: users, isLoading } = useGetBusinessUsersQuery(business?._id);
  const [deleteProduct, { error, isSuccess }] = useDeleteUserMutation();

  const columns = [
    {
      title: "Adı",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Soyadı",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href={`/admin/kullanici/${record?._id}`}>
            <Button color="primary">Güncelle</Button>
          </a>
          <Popconfirm
            title="Kişi Sil"
            description="Bu kişiyi silmek istediğine emin misin?"
            onConfirm={() => deleteProduct(record?._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Üye Başarıyla Silindi");
    }
  }, [error, isSuccess]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-white mx-3 rounded-lg">
      <MetaData text={"Kullanıcılar"} />

      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5">
       {business?.name} ADLI ŞİRKETE AİT ÜYELER
      </h1>
      <TableList columns={columns} data={users?.users} />
    </div>
  );
};

export default GetBusinessUsers;
