import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useCreateQrMutation,
  useDeleteQrMutation,
  useGetQrQuery,
} from "../../../redux/api/qrApi";
import MetaData from "../../layouts/MetaData";
import { useParams } from "react-router-dom";
import { Button, Popconfirm, Space } from "antd";
import TableList from "../../layouts/TableList";
import Loader from "../../layouts/Loader";

const Qr = () => {
  const [data, setData] = useState({ url: "" });
  const [tableNumber, setTableNumber] = useState(0);

  const { business } = useParams();

  const { data: qr, isLoading } = useGetQrQuery(business);
  const [createQr, { error, isSuccess }] = useCreateQrMutation();
  const [deleteQr, { error: deleteError, isSuccess: deleteSuccess }] =
    useDeleteQrMutation();

  const ImageDownload = async (qrUrl, title) => {
    const imageUrl = qrUrl;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.png`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url); // Bellekte yer kaplamaması için URL'i temizleyin
  };
  const decreaseBtn = () => {
    if (tableNumber > 0) setTableNumber(tableNumber - 1);
  };
  const increaseBtn = () => {
    if (tableNumber < 50) {
      setTableNumber(tableNumber + 1);
    } else {
      toast.error("Masa sayısı 50'den fazla olamaz.");
    }
  };
  const submitHandler = () => {
    toast.warning("Bu işlem uzun sürebilmektedir.");
    createQr({ url: data.url, number: tableNumber, business });
  };
  const deleteHandler = (id) => {
    deleteQr({ id });
  };

  const columns = [
    {
      title: "Qr Resmi",
      render: (_, record) => (
        <img
          src={record?.image?.url}
          alt="qr"
          className="w-16 h-16 object-cover"
        />
      ),
    },
    {
      title: "Qr Adı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => ImageDownload(record?.image?.url, record?.title)}>
            <Button color="primary">İndir</Button>
          </a>
          <Popconfirm
            title="Qr'ı Sil"
            description="Bu qr kodu silmek istediğine emin misin?"
            onConfirm={() => deleteHandler(record?._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error || deleteError) toast.error(error?.data?.message);
    if (isSuccess) toast.success("Yeni Qr Oluşturuldu.");
    if (deleteSuccess) toast.success("Qr Silindi.");
    if (window.location.host) {
      setData({ url: window.location.origin + "/" + business });
    }
  }, [error, isSuccess, business, deleteSuccess, deleteError, qr]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex max-xl:flex-col pb-10 justify-center px-3 bg-white p-2 m-3 rounded-md">
      <MetaData text={"Qr"} />
      <div className="flex flex-col my-5 sm:px-10 flex-1 xl:w-1/4 xl:hidden">
        <div className="">
          <label htmlFor="" className="font-semibold">
            Qr Urlsi
          </label>
          <input
            type="text"
            className="border rounded ps-1 py-2 w-full focus:ring-2 focus:ring-orange-500 focus:outline-none"
            placeholder="Url..."
            value={data.url}
            onChange={(e) => setData({ url: e.target.value })}
          />
        </div>
        <div className="flex flex-col">
          <div className="mt-5 flex gap-2">
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={decreaseBtn}
            >
              Masa Azalt
            </button>
            <input
              type="number"
              value={tableNumber}
              disabled
              className="max-w-36 bg-orange-200 rounded-lg border ps-1 text-center font-semibold"
            />
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={increaseBtn}
            >
              Masa Ekle
            </button>
          </div>
          <div className="mt-5">
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={submitHandler}
            >
              Yeni Qr Code Oluştur
            </button>
          </div>
        </div>
      </div>
      <div className="flex-2 xl:w-3/4">
        <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5">Qr Kodlar</h1>
        <TableList columns={columns} data={qr?.qr} />
      </div>
      <div className="flex flex-col my-5 sm:px-10 flex-1 xl:w-1/4 max-xl:hidden">
        <div className="">
          <label htmlFor="" className="font-semibold">
            Qr Urlsi
          </label>
          <input
            type="text"
            className="border rounded ps-1 py-2 w-full focus:ring-2 focus:ring-orange-500 focus:outline-none"
            placeholder="Url..."
            value={data.url}
            onChange={(e) => setData({ url: e.target.value })}
          />
        </div>
        <div className="flex flex-col">
          <div className="mt-5 flex gap-2">
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={decreaseBtn}
            >
              Masa Azalt
            </button>
            <input
              type="number"
              value={tableNumber}
              disabled
              className="max-w-36 bg-orange-200 rounded-lg border ps-1 text-center font-semibold"
            />
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={increaseBtn}
            >
              Masa Ekle
            </button>
          </div>
          <div className="mt-5">
            <button
              className="bg-orange-500 text-white heebo max-sm:w-full w-full py-4 rounded-md px-5 hover:bg-orange-600 transition-all"
              onClick={submitHandler}
            >
              Yeni Qr Code Oluştur
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qr;
