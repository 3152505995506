import React, { useEffect, useState } from "react";
import { useSprings, animated, to as interpolate } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import Reveal from "./Reveal";
import { useNavigate, useParams } from "react-router-dom";
import Loader from './Loader'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGetProductsOfMonthQuery } from "../../redux/api/productApi";
gsap.registerPlugin(ScrollTrigger);

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

function Deck() {
  const [cards, setCards] = useState([]);
  const {business} = useParams();
  const { data: products, isLoading } = useGetProductsOfMonthQuery({slug: business});
  const navigate = useNavigate();
  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2;
      const dir = xDir < 0 ? -1 : 1;
      if (!down && trigger) gone.add(index);

      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
        const scale = down ? 1.1 : 1;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });

      if (!down && gone.size === cards.length) {
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
      }
    }
  );

  useEffect(() => {
    if (products) {
      setCards(products?.products);
    }
  }, [products]);

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={"deck"} key={i} style={{ x, y }}>
          <animated.div
            {...bind(i)}
            className={"flex items-end justify-center"}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(${products?.products[i].images[0]?.url})`,
            }}
          >
            <p className="mb-2 select-none">{cards[i].name}</p>
          </animated.div>
        </animated.div>
      ))}
    </>
  );
}

const SwipeCard = ({ t, color }) => {

  useEffect(() => {
    gsap.fromTo(
      ".swipe-fade-left",
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".swipe-fade-left",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".swipe-fade-right",
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".swipe-fade-right",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center my-10 ">
        <span className="cormorant-title-bold swipe-fade-left" style={{color}}>
          BEST
        </span>
        <div className="relative">
          <h1 className="max-md:text-4xl text-6xl font-bold cormorant swipe-fade-right text-center">
          Bu Ayın En Sevilenleri
          </h1>
          <div className="absolute bottom-0 left-1/2 transform translate-x-1/2 translate-y-1/2">
            <p className="quentin max-md:text-4xl text-6xl swipe-fade-left">
              ürün
            </p>
          </div>
        </div>
      </div>
      <div className="overflow-hidden max-sm:py-10">
        <Reveal>
          <div className="w-full h-[800px] -mt-64 flex justify-center overflow-hidden">
            <div className={"container mt-32 overflow-hidden"}>
              <Deck />
            </div>
          </div>
        </Reveal>

        <p className="blinking-text text-center" style={{color}}>
          <i class="ri-arrow-left-line" style={{color}}></i> Kartları Kaydır
          <i class="ri-arrow-right-line" style={{color}}></i>
        </p>
      </div>
    </>
  );
};

export default SwipeCard;
