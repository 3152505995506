import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import { useParams } from "react-router-dom";
import { useGetBusinessWithSlugQuery } from "../../redux/api/businessApi";

const Footer = () => {
  const [businessName, setBusinessName] = useState("");
  const [logo, setLogo] = useState("/logo.png");
  const { data: categories } = useGetCategoriesQuery({ slug: businessName });

  const { data: business } = useGetBusinessWithSlugQuery(businessName);
  const footerTheme = JSON.parse(localStorage.getItem("theme"))

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }

    if (business) {
      setLogo(business?.business?.logo?.url);
    }
  }, [business]);

  return (
    <div className=" text-white md:flex justify-center py-10 items-center overflow-hidden" style={{backgroundColor: footerTheme?.footer?.backgroundColor ? footerTheme?.footer?.backgroundColor : "black"}}>
      <div className="grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        <div className="flex justify-center items-center w-full">
          <a href="">
            <img
              src={logo ? logo : "e-waiter-logo.png"}
              alt="logo"
              className="max-md:h-32 h-48 w-full"
            />
          </a>
        </div>

        <div className="max-md:ps-5">
          <h1 className="text-4xl cormorant-title" style={{color: footerTheme?.footer?.textColor ? footerTheme?.footer?.textColor : "#f97316"}}>
            Kullanışlı Linkler
          </h1>
          <div className="flex flex-col ">
            <a
              href={`/${businessName}/`}
              className="hover:text-orange-500 transition-all text-lg text-white"
            >
              Anasayfa
            </a>
            <a
              href={`/${businessName}/urunler`}
              className="hover:text-orange-500 transition-all text-lg text-white"
            >
              Ürünler
            </a>
          </div>
        </div>

        <div className="max-md:ps-5">
          <h1 className="text-4xl cormorant-title" style={{color: footerTheme?.footer?.textColor ? footerTheme?.footer?.textColor : "#f97316"}}>
            Kategoriler
          </h1>
          <div className="flex flex-col ">
            {categories?.categories?.slice(0,7).map((cate) => (
              <a
                href={`/kategori/${cate?.slug}`}
                className="hover:text-orange-500 transition-all text-md text-white"
              >
                {cate?.name}
              </a>
            ))}
          </div>
        </div>

        <div className="max-md:ps-5">
          <h1 className="text-4xl cormorant-title" style={{color: footerTheme?.footer?.textColor ? footerTheme?.footer?.textColor : "#f97316"}}>İletişim</h1>
          <div className="flex flex-col gap-4">
            <a
              href="#"
              className="text-white hover:text-orange-500 transition-all"
            >
              Adres: {business?.business?.contact?.address}
            </a>
            <a
              href={`tel:${business?.business?.contact?.phone}`}
              className="text-white hover:text-orange-500 transition-all"
            >
              Telefon: {business?.business?.contact?.phone}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
