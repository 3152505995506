import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetCategoryDetailsQuery,
  useUpdateCategoryMutation,
} from "../../../redux/api/categoryApi";
import { toast } from "react-toastify";
import MetaData from "../../layouts/MetaData";

const EditCategory = () => {
  const [data, setData] = useState({ name: "" });
  const { name } = data;
  const [image, setImage] = useState([]);
  const [previewImages, setPreviewImages] = useState({});

  const { id } = useParams();
  const { data: category } = useGetCategoryDetailsQuery({ id });
  const [updateCategory, { error, isSuccess, isLoading }] = useUpdateCategoryMutation();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImages({
          url: reader.result,
          public_id: reader?.result,
          isBase64: true,
        });
        setImage({
          url: reader.result,
          public_id: reader?.result,
          isBase64: true,
        });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCategory({ name, image, id });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kategori güncellendi.");
    }
    if (category) {
      setData({ name: category?.category?.name });
      setImage({
        url: category?.category?.image?.url,
        public_id: category?.category?.image?.public_id,
      });
      setPreviewImages({
        url: category?.category?.image?.url,
        public_id: category?.category?.image?.public_id,
      });
    }
  }, [error, isSuccess, category]);

  return (
    <div className="mx-3 bg-white p-3 rounded-md">
      <MetaData text={"Kategori Güncelle"} />

      <div className="mb-5">
        <h1 className="text-4xl font-bold cormorant-title ">
          Kategori Güncelle
        </h1>
      </div>
      <form method="post" onSubmit={onSubmit}>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="name"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Adı
          </label>
          <input
            type="text"
            name="name"
            value={name}
            placeholder="Ürün adı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="image"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Resmi
          </label>
          <input
            type="file"
            name="image"
            onChange={(e) => onChangeImage(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>
        <div className="flex gap-2 sm:ps-5 mb-3">
          <div className="relative cursor-pointer">
            <img
              src={previewImages?.url}
              alt="p-img"
              className="h-16 w-20 rounded-md"
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Güncelleniyor...": "Güncelle"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCategory;
