import React, { useEffect, useState } from "react";
import TableList from "../layouts/TableList";
import MetaData from "../layouts/MetaData";
import { Button, Popconfirm, Space } from "antd";
import { useGetBusinessesQuery } from "../../redux/api/businessApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const GetAllBusiness = () => {
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();

  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const { data: businesses } = useGetBusinessesQuery();

  const deleteFunc = (id) => {};

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
    if (!loading) {
      if (!isAuthenticated) {
        navigate(`/${businessName}/giris`);
      }
    }
  }, [isAuthenticated, businessName, loading]);

  const setBusinessNameFunc = (slug) => {
    setBusinessName(slug);
    localStorage.setItem("b_name", slug);
  };

  const routeHandle = (slug) => {
    setBusinessNameFunc(slug)
    window.location.href = `/${slug}/admin/urunler`
  }

  const columns = [
    {
      title: "İşletme Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
            <Button color="primary" onClick={() => routeHandle(record?.slug)}>Güncelle</Button>
          <a href={`/${record?.slug}/admin/urunler/`}>
            <Button
              color="primary"
              onClick={() => setBusinessNameFunc(record?.slug)}
            >
              İşetmeyi Yönet
            </Button>
          </a>
          <Popconfirm
            title="Kategoriyi Sil"
            description="Bu Kategoriyi silmek istediğine emin misin?"
            onConfirm={() => deleteFunc({ id: record?._id })}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div className="bg-white mx-3 rounded-lg pb-3">
      <MetaData text={"Tüm Kategoriler"} />

      <div className="mx-3 rounded-lg bg-white ">
        <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b pb-1">
          İŞLETMELER
        </h1>
        <TableList columns={columns} data={businesses?.businesses} />
      </div>
    </div>
  );
};

export default GetAllBusiness;
