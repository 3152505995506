import React, { useEffect, useState } from "react";
import SliderComp from "../components/layouts/Slider";
import Products from "../components/products/Products";
import Categories from "../components/categories/Categories";
import SwipeCard from "../components/layouts/SwipeCard";
import {
  useGetLatestProductsQuery,
  useGetProductsQuery,
} from "../redux/api/productApi";
import Loader from "../components/layouts/Loader";
import MetaData from "../components/layouts/MetaData";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/layouts/Footer";
import { useGetBusinessWithSlugQuery } from "../redux/api/businessApi";

const Home = () => {
  const { business } = useParams();
  const [searchParams] = useSearchParams();
  const [theme, setTheme] = useState(null);

  const { data: products, isLoading } = useGetProductsQuery({ slug: business });
  const { data: businessData, isLoading: businessLoading } =
    useGetBusinessWithSlugQuery(business);
  const { data: latestProducts } = useGetLatestProductsQuery({
    slug: business,
  });

  useEffect(() => {
    if (business) {
      localStorage.setItem("b_name", business);
    }

    if (businessData) {
      localStorage.setItem(
        "theme",
        JSON.stringify(businessData?.business?.theme)
      );
    } else {
      localStorage.removeItem("theme");
    }

    if (JSON.parse(localStorage.getItem("theme"))) {
      setTheme(JSON.parse(localStorage.getItem("theme")));
    }
  }, [business, businessData]);

  if (isLoading || businessLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="overflow-hidden">
        <MetaData text={"Anasayfa"} />
        <div className="overflow-hidden">
          <SliderComp />
        </div>
        {/* CATEGORIES */}
        <div
          className=""
          style={{ backgroundColor: theme?.body?.backgroundColor || "#fff" }}
        >
          <Categories
            slug={business}
            color={theme?.body?.textColor || "#fff"}
          />
        </div>

        {/* PRODUCTS */}
        <div
          className="bg-gradient-to- t from-[#FCF8ED] to-transparent overflow-hidden"
          style={{ backgroundColor: theme?.body?.backgroundColor || "#fff" }}
        >
          <Products
            text={"incele"}
            title={"Ürünler"}
            subtitle={"En Yeni Trendleri Keşfedin"}
            products={products}
            business={business}
            color={theme?.body?.textColor || "#fff"}
          />
        </div>

        {/* SWIPE-CARS */}
        <div
          className="bg- [#FCF8ED] -mt-10 pt-10 -mb-10 pb-10 overflow-hidden"
          style={{ backgroundColor: theme?.body?.backgroundColor || "#fff" }}
        >
          <div className="overflow-hidden">
            <SwipeCard color={theme?.body?.textColor || "#fff"} />
          </div>
        </div>

        {/* PRODUCTS */}
        <div
          className="bg-gradient-to- b from-[#FCF8ED] to-transparent overflow-hidden py-10"
          style={{ backgroundColor: theme?.body?.backgroundColor || "#fff" }}
        >
          <Products
            text={"kesfet"}
            title={"En Yeni Trendleri Keşfedin"}
            subtitle={"Tarzınıza Uygun Seçenekler"}
            products={latestProducts}
            color={theme?.body?.textColor || "#fff"}
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
