import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import Loader from "../layouts/Loader";
import { useGetBusinessWithSlugQuery } from "../../redux/api/businessApi";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
const { business: slug } = useParams();

const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
const { data: business, isLoading: businessLoading } =
  useGetBusinessWithSlugQuery(slug);

const [showSidebar, setShowSidebar] = useState(false);
const [businessName, setBusinessName] = useState(() =>
  localStorage.getItem("b_name") || ""
);

useEffect(() => {
  if (!loading) {
    if (!isAuthenticated) {
      navigate(`/${businessName}/giris`);
    }
  }

  if (businessName && slug && slug !== user?.data?.business?.slug) {
    if (user?.data?.business?.slug) {
      navigate(`/${businessName}/admin/urunler`);
    }
  }
}, [isAuthenticated, loading, slug, businessName, navigate, user]);


  if (loading || businessLoading) {
    return <Loader />;
  }

  return (
    <div className="mt-16 flex">
      <div className={`border-r-2 h-[95vh] py-5 ${ showSidebar ? "lg:w-52 xl:w-1/6" : "w-20" } transition-all`}>
        <Sidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      </div>
      <div className="bg-indigo-200 py-5 w-full">{React.cloneElement(children, {business: business?.business})}</div>
    </div>
  );
};

export default ProtectedRoute;
