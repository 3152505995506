import React, { useEffect, useState } from "react";
import { useGetProductsQuery } from "../redux/api/productApi";
import { useGetCategoriesQuery } from "../redux/api/categoryApi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import MetaData from "../components/layouts/MetaData";
import Footer from "../components/layouts/Footer";

const Products = () => {
  const [keyword, setKeyword] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState();

  const [showFilter, setShowFilter] = useState(false);
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();
  const { business } = useParams();

  const params = { keyword, slug: business };
  const theme = JSON.parse(localStorage.getItem("theme"));

  min != null && (params.min = min);
  max != null && (params.max = max);

  const { data: products, isLoading } = useGetProductsQuery(params);

  const onChange = (e) => {
    if (e.target.name === "min") {
      setMin(e.target.value);
      if (e.target.value === "" && e.target.name === "min") {
        setMin(0);
      }
    }
    if (e.target.name === "max") {
      setMax(e.target.value);
      if (e.target.value === "" && e.target.name === "max") {
        setMax(9999999);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
  }, [keyword, min, max]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="pt-20 min-h-screen" style={{backgroundColor: theme?.body?.backgroundColor || "#fff"}}>
        <MetaData text={"Ürünler"} />
        <div className="flex max-md:flex-col max-sm:px-5 sm:px-10 lg:px-16 xl:px-32 gap-5 min-h-[80vh] mb-32">
          <div className="border h-full md:sticky left-0 top-20 p-5 rounded-lg sm:mt-10 bg-orange-500">
            <div className="flex flex-col gap-4">
              <button
                className="text-4xl cormorant-title bg-black text-white rounded-md py-2 px-14"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filtre
              </button>
              <div className={`${showFilter && "hidden"}`}>
                <div className="">
                  <h1 className="cormorant-title-bold text-xl">Arama:</h1>
                  <input
                    type="text"
                    name="search"
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder="Bir kelime giriniz"
                    className="border focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 rounded-md py-2 w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <h1 className="cormorant-title-bold text-xl mt-5">Fiyat:</h1>
                  <input
                    type="number"
                    name="min"
                    placeholder="Min(₺)"
                    onChange={onChange}
                    className="border focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 rounded-md mb-2 py-2"
                  />
                  <input
                    type="number"
                    name="max"
                    placeholder="Max(₺)"
                    onChange={onChange}
                    className="border focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500 rounded-md py-2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex justify-between items-center mb-3">
              <h1 className="max-md:text-2xl text-4xl">Ürünler</h1>
              <p>
                Toplam Ürün Sayısı: <span>{products?.products?.length}</span>
              </p>
            </div>

            <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
              {products?.products?.map((product) => (
                <a
                  href={`/${businessName}/urun/${product?.category?.slug}/${product?._id}`}
                  className="h-full max-sm:max-h-[400px] max-sm:w-full "
                >
                  <div className="group h-full border-[#E6E6E6] border flex flex-col rounded-md">
                    <div className="h-full overflow-hidden cursor-pointer rounded-t-md">
                      <img
                        src={product?.images[0]?.url ? product?.images[0]?.url : "/images/default_product.png"}
                        alt=""
                        className="h-full group-hover:scale-105 transition-all w-full"
                      />
                    </div>
                    <div className="flex justify-between items-center py-5 px-2">
                      <h1 className="text-2xl xl:text-3xl text-black">
                        {product?.name}
                      </h1>
                      <span className="text-orange-500">{product?.price}₺</span>
                    </div>
                  </div>
                </a>
              ))}
            </div>
            {products?.products?.length === 0 && (
              <div className="flex justify-center items-center">
                <div className="text-center">
                  <h1 className="text-4xl font-bold">Ürün bulunamadı.</h1>
                  <p>
                    Bu kelime(ler) ile kayıtlı bir ürün bulunamadı. ({keyword})
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Products;
