import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../components/layouts/Breadcrumb";
import Tabs from "../components/layouts/Tabs";
import { useGetProductDetailsQuery } from "../redux/api/productApi";
import { useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import MetaData from "../components/layouts/MetaData";
import { addToCart as addToCartAction } from "../redux/features/cartSlice";
import { useDispatch } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import Footer from "../components/layouts/Footer";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const imageRef = useRef(null);

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [activeTab, setActiveTab] = useState("desc");
  const [path, setPath] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [fullScreen, setFullScreen] = useState({
    show: false,
    img: "",
    index: 0,
  });
  const [startIndex, setStartIndex] = useState(0);
  const [images, setImages] = useState([]);

  const { id } = useParams();
  const { data: prdct, isLoading } = useGetProductDetailsQuery({ id });
  const product = prdct?.product;

  const theme = JSON.parse(localStorage.getItem("theme"));

  const handleAddToCart = (product) => {
    dispatch(
      addToCartAction({
        _id: product._id,
        name: product.name,
        price: product.price,
        image: product.images[0]?.url,
        quantity: 1, // Varsayılan miktar
      })
    ); // Redux aksiyonu doğru isimle çağrılıyor.
  };
  const previouseBtn = (e) => {
    e.stopPropagation();
    const newIndex = (fullScreen.index - 1 + images.length) % images.length;
    setFullScreen({
      ...fullScreen,
      img: images[newIndex]?.url,
      index: newIndex,
    });
  };
  const nextBtn = (e) => {
    e.stopPropagation();
    const newIndex = Number(fullScreen?.index + 1) % images.length;
    setFullScreen({
      ...fullScreen,
      img: images[newIndex]?.url,
      index: newIndex,
    });
  };
  const selectedImageHandler = (url, index) => {
    onChangeSelectedImage({ url: url });
    setStartIndex(index);
  };
  const onChangeSelectedImage = ({ url }) => setSelectedImage(url);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setImageWidth(entries[0].contentRect.width);
        setImageHeight(entries[0].contentRect.height);
      }
    });

    // imageRef mevcut olduğunda gözlemleyin
    if (imageRef.current) {
      resizeObserver.observe(imageRef.current);
    }

    if (localStorage.getItem("b_name")) {
      setBusinessName(localStorage.getItem("b_name"));
    }
    if (product) {
      setSelectedImage(product?.images[0]?.url);
      setPath([
        {
          name: "Anasayfa",
          url: `/${businessName}`,
        },
        {
          name: `${product?.category?.name}`,
          url: `/${businessName}/kategori/${product?.category?.slug}`,
        },
        {
          name: `${product?.name}`,
          url: `/${businessName}/urun/${product?.category?.slug}/${product?._id}`,
        },
      ]);
      setImages(product?.images);
    }
    if (fullScreen.show) {
      document.body.style.overflow = "hidden"; // Sayfa kaydırmasını devre dışı bırak
    } else {
      document.body.style.overflow = "auto"; // Sayfa kaydırmasını etkinleştir
    }

    // Cleanup: Observer'ı bileşen kaldırıldığında durdurun
    return () => {
      if (imageRef.current) {
        resizeObserver.unobserve(imageRef.current);
      }
    };
  }, [product, businessName, fullScreen]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-10 min-h-screen pt-10" style={{backgroundColor: theme?.body?.backgroundColor || "#fff"}}>
        <MetaData text={product?.name ? product?.name : "Ürün detayı"} />
        {/* BREADCRUMB-WRAPPER */}
        <div className="max-lg:px-10 pt-10 lg:px-32 2xl:px-64 max-md:relative z-10">
          <Breadcrumb paths={path} slug={businessName} color={theme?.body?.textColor || "#f97316"} />
        </div>

        {fullScreen.show && (
          <div className="fixed top-0 left-0 z-50 w-screen h-screen">
            {/* Arka plan */}
            <div
              className="bg-black bg-opacity-80 absolute inset-0 z-40 pointer-events-auto"
              onClick={() => setFullScreen({ show: false, img: "", index: 0 })}
            />
            {/* Görsel ve navigasyon */}
            <div className="absolute z-50 flex justify-center items-center inset-0">
              {/* Kapama butonu */}
              <div className="absolute top-5 right-7 z-50">
                <IoCloseCircleOutline
                  color="red"
                  size={28}
                  className="cursor-pointer"
                  onClick={() =>
                    setFullScreen({ show: false, img: "", index: 0 })
                  }
                />
              </div>
              {/* Önceki resim butonu */}
              <button
                onClick={previouseBtn}
                className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-20 p-2 rounded-full z-50"
              >
                &#8249;
              </button>

              {/* Görsel */}
              <img
                src={fullScreen.img}
                alt="img"
                className="max-sm:max-h-[80vh] sm:max-h-[90vh] max-w-[90vw] h-auto w-auto object-contain z-40"
              />

              {/* Sonraki resim butonu */}
              <button
                onClick={nextBtn}
                className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-20 p-2 rounded-full z-50"
              >
                &#8250;
              </button>
            </div>
          </div>
        )}

        <div className="flex max-md:flex-col max-md:px-10 md:px-16 lg:px-32 xl:px-64 mt-10 gap-5 max-sm:justify-center max-sm:items-center">
          {/* IMAGES-WRAPPER */}
          <div className="flex max-md:flex-col gap-1 w-full md:w-4/5">
            {/* FOR-COMPUTER-IMAGES */}
            <div
              className={`flex flex-col gap-2 overflow-auto max-md:hidden`}
              style={{ maxHeight: `${imageHeight}px` }}
            >
              {product?.images?.length > 0 ? (
                product?.images?.map((img, index) => (
                  <img
                    key={img?.url}
                    src={img?.url}
                    alt="img"
                    style={{borderColor: theme?.body?.textColor || "#f97316"}}
                    className={`h-20 w-36 cursor-pointer rounded-md ${img?.url === selectedImage && "border-2"
                      }`}
                    onClick={() => selectedImageHandler(img?.url, index)}
                  />
                ))
              ) : (
                <img
                  src={"/images/default_product.png"}
                  alt="img"
                  className={`h-20 w-36 cursor-pointer rounded-md ${"/images/default-product.png" === selectedImage && "border-2 border-orange-500"
                    }`}
                  onClick={() => selectedImageHandler("/images/default_product.png", 0)}
                />
              )}
            </div>
            {/* SELECTED-IMAGES */}
            <div className="">
              <img
                src={selectedImage}
                alt="img"
                ref={imageRef}
                onClick={() =>
                  setFullScreen({
                    show: true,
                    img: selectedImage,
                    index: startIndex,
                  })
                }
                className="max-h-[700px] h-full"
              />
            </div>
            {/* FOR-MOBILE-IMAGES */}
            <div
              className={`flex md:hidden gap-2 w-full overflow-auto`}
              style={{ maxWidth: `${imageWidth}px` }}
            >
              {product?.images?.map((img) => (
                <img
                  key={img?.url}
                  src={img?.url}
                  alt="img"
                  style={{borderColor: theme?.body?.textColor || "#f97316"}}
                  className={`h-20 w-16 rounded-md ${img?.url === selectedImage && "border-2"
                    }`}
                  onClick={() => onChangeSelectedImage({ url: img?.url })}
                />
              ))}
            </div>
          </div>
          {/* CONTENT-WRAPPER */}
          <div className="p-4 w-full">
            <h1 className="max-sm:text-4xl sm:text-4xl font-semibold mb-5">
              {(product?.name).toUpperCase()}
            </h1>
            <p className="">{product?.subtitle}</p>
            <div className="flex justify-between items-center">
              <p className="mt-5">
                <a href={`/${businessName}/kategori/${product?.category?.slug}`}>
                  KATEGORİ:{" "}
                  <span className="font-bold" style={{color: theme?.body?.textColor || "#f97316"}}>
                    {product?.category?.name}
                  </span>
                </a>
              </p>
              <p className="mt-5 text-2xl">
                Fiyat:{" "}
                <span className="font-bold" style={{color: theme?.body?.textColor || "#f97316"}}>
                  {product?.price}₺
                </span>
              </p>
            </div>
            {/* <div className="">
            <button onClick={() => handleAddToCart(product)}>
              Sepete EKle
            </button>
          </div> */}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mt-20 max-lg:px-10 lg:px-32 2xl:px-64">
          <div className="">
            <Tabs setActiveTab={setActiveTab} activeTab={activeTab} color={theme?.body?.textColor || "#f97316"} />
          </div>

          <div className="h-[2px] w-full mb-10" style={{backgroundColor: theme?.body?.textColor || "#f97316"}} />

          <div className="">
            {activeTab === "desc" ? (
              <p className="text-center italic">{product?.desc}</p>
            ) : (
              <p className="text-center italic"></p>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductDetails;
