import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://qr-menu-project.onrender.com/api`,
    credentials: "include",
  }),
  tagTypes: ["Business"],
  endpoints: (builder) => ({
    getBusinesses: builder.query({
      query: () => "/businesses",
      providesTags: ["Business"],
    }),
    getBusinessWithSlug: builder.query({
      query: (slug) => `/business/${slug}`,
      providesTags: ["Business"],
    }),
    updateLogo: builder.mutation({
      query: (body) => ({
        url: `/update/logo/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Business"],
    }),
    createBusiness: builder.mutation({
      query: (body) => ({
        url: "/create/business",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Business"],
    }),
    updateContact: builder.mutation({
      query: (body) => ({
        url: `/update/business/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Business"],
    }),
    updateTheme: builder.mutation({
      query: (body) => ({
        url: `/update/theme/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Business"],
    }),
    deleteBusiness: builder.mutation({
      query: (slug) => ({
        url: `/${slug}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Business"],
    }),
  }),
});

export const {
  useGetBusinessesQuery,
  useGetBusinessWithSlugQuery,
  useUpdateLogoMutation,
  useCreateBusinessMutation,
  useUpdateContactMutation,
  useUpdateThemeMutation,
  useDeleteBusinessMutation,
} = businessApi;
