import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://qr-menu-project.onrender.com/api`,
    credentials: "include",
  }),
  tagTypes: ["Products"],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => ({
        url: `/${params?.slug}/products`,
        params: {
          keyword: params?.keyword,
          "price[gte]": params?.min,
          "price[lte]": params?.max,
        },
      }),
      providesTags: ["Products"],
    }),
    getProductsOfBusiness: builder.query({
      query: (params) => ({
        url: `/${params?.slug}/get-all-products`,
        params: {
          keyword: params?.keyword,
          "price[gte]": params?.min,
          "price[lte]": params?.max,
        },
      }),
      providesTags: ["Products"],
    }),
    getLatestProducts: builder.query({
      query: ({ slug }) => `/${slug}/products?sort=-1`,
      providesTags: ["Products"],
    }),
    getProductsOfMonth: builder.query({
      query: ({ slug }) => `/${slug}/productsOfMonth`,
      providesTags: ["Products"],
    }),
    getProductOfCategory: builder.query({
      query: ({ slug, business, params }) => ({
        url: `/${business}/category/${slug}`,
        params: {
          keyword: params?.keyword,
        },
      }),
      providesTags: ["Products"],
    }),
    getProductDetails: builder.query({
      query: ({ id }) => `/product/${id}`,
      providesTags: ["Products"],
    }),
    createProduct: builder.mutation({
      query(body) {
        return {
          url: `/${body?.business}/create/product`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation({
      query(id) {
        return {
          url: `/product/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Products"],
    }),
    updateProduct: builder.mutation({
      query(body) {
        return {
          url: `/product/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductsOfBusinessQuery,
  useGetLatestProductsQuery,
  useGetProductsOfMonthQuery,
  useGetProductOfCategoryQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductDetailsQuery,
  useUpdateProductMutation,
} = productApi;
