import React, { useEffect, useState } from "react";
import MetaData from "../layouts/MetaData";
import { ColorPicker } from "antd";
import Button from "../layouts/Button";
import { motion } from "framer-motion";
import { useUpdateThemeMutation } from "../../redux/api/businessApi";
import { toast } from "react-toastify";
import { Squash } from "hamburger-react";

const UpdateTheme = ({ business }) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    header: {
      backgroundColor: "#ffffff",
      buttonColor: "#000000",
    },
    body: {
      backgroundColor: "#ffffff",
      textColor: "#f97316",
      buttonColor: "#f97316",
    },
    footer: { backgroundColor: "#000000", textColor: "#f97316" },
  });

  const [updateTheme, { error, isSuccess }] = useUpdateThemeMutation();

  const stepVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };
  const submitHandle = () => {
    const updateData = {
      body: data,
      id: business?._id,
    };
    updateTheme(updateData);
    if (localStorage.getItem("theme")) {
      localStorage.removeItem("theme");
      localStorage.setItem("theme", JSON.stringify(data));
    }else {
      localStorage.setItem("theme", JSON.stringify(data));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Tema Başarıyla Güncellendi.");
    }
    if (business) {
      setData(business?.theme);
    }
  }, [error, isSuccess, business]);

  return (
    <div className="bg-white mx-3 rounded-md max-sm:min-h-screen h-full ">
      <MetaData text={"Tema Güncelle"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        Tema Güncelle
      </h1>

      {/* header */}
      {step === 0 && (
        <motion.div
          initial="hidden"
          exit="exit"
          animate="visible"
          variants={stepVariants}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col w-full h-full bg">
            {/* top */}
            <div
              className="h-16 w-full relative flex justify-center items-center border"
              style={{
                backgroundColor: data?.header?.backgroundColor || "#fff",
              }}
            >
              {/* logo */}
              <div className="">
                <img src={business?.logo?.url} alt="" className="h-16" />
              </div>
              {/* button */}
              <div className="absolute right-0 top-0 w-full h-16 flex justify-end items-center sm:pe-10">
                <Squash color={data?.header?.buttonColor || "black"} />
              </div>
            </div>

            {/* content */}
            <div className="max-sm:px-8 sm:px-20 mt-20">
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg sm:text-xl font-semibold">
                  Header Kısmı İçin Renk Seçimi:
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  value={data?.header?.backgroundColor}
                  mode={"single"}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      header: {
                        ...prevValue.header,
                        backgroundColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg sm:text-xl font-semibold">
                  Header Buton İçin Renk Seçimi:
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  value={data?.header?.buttonColor}
                  mode={"single"}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      header: {
                        ...prevValue.header,
                        buttonColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {/* body */}
      {step === 1 && (
        <motion.div
          initial="hidden"
          exit="exit"
          animate="visible"
          variants={stepVariants}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col w-full h-full">
            <div
              className={`max-sm:px-8 sm:px-16 mt-5 w-full h-64 overflow-hidden`}
              style={{ backgroundColor: data?.body?.backgroundColor }}
            >
              <div className="relative z-10 flex flex-col w-full items-center mt-2">
                <span
                  className="cormorant-title-bold"
                  style={{ color: data?.body?.textColor || "#f97316" }}
                >
                  liste
                </span>
                <div className="relative">
                  <h1 className="text-2xl font-bold cormorant">Kategoriler</h1>
                  <div className="absolute -bottom-5 -right-6">
                    <p className="quentin text-2xl">lezzet</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-4 place-items-center place-content-center max-md:hidden gap-5 mt-10">
                <div className="relative w-full h-full max-h-32">
                  <img
                    src="/images/default_product.png"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    kategori 1
                  </div>
                </div>
                <div className="relative w-full h-full max-h-32">
                  <img
                    src="/images/default_product.png"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    kategori 2
                  </div>
                </div>
                <div className="relative w-full h-full max-h-32">
                  <img
                    src="/images/default_product.png"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    kategori 3
                  </div>
                </div>
                <div className="relative w-full h-full max-h-32">
                  <img
                    src="/images/default_product.png"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    kategori 4
                  </div>
                </div>
              </div>
            </div>

            <div className="px-20 mt-20 flex flex-col gap-5">
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg sm:text-xl font-semibold">
                  Anasayfa Arkaplan Kısmı İçin Renk Seçimi:{" "}
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  mode={"single"}
                  value={data?.body?.backgroundColor}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      body: {
                        ...prevValue.body,
                        backgroundColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg sm:text-xl font-semibold">
                  Anasayfa Renkli Yazı Kısmı İçin Renk Seçimi:
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  mode={"single"}
                  value={data?.body?.textColor}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      body: {
                        ...prevValue.body,
                        textColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg sm:text-xl font-semibold">
                  Anasayfa Buton Kısmı İçin Renk Seçimi:
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  mode={"single"}
                  value={data?.body?.buttonColor}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      body: {
                        ...prevValue.body,
                        buttonColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {/* footer */}
      {step === 2 && (
        <motion.div
          initial="hidden"
          exit="exit"
          animate="visible"
          variants={stepVariants}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col w-full h-full">
            <div
              className={`px-16 mt-5 w-full h-64 max-lg:hidden`}
              style={{ backgroundColor: data?.footer?.backgroundColor }}
            >
              <div className="flex items-center justify-center gap-32 w-full h-full">
                <div className="h-32">
                  <img src={business?.logo?.url} alt="" className="h-full" />
                </div>
                <div className="">
                  <h1
                    className={` text-3xl`}
                    style={{ color: data?.footer?.textColor }}
                  >
                    Kullanışlı Linkler
                  </h1>
                  <div className="flex flex-col text-white">
                    <span>Link 1</span>
                    <span>Link 2</span>
                    <span>Link 3</span>
                  </div>
                </div>
                <div className="">
                  <h1
                    className={` text-3xl`}
                    style={{ color: data?.footer?.textColor }}
                  >
                    Kategoriler
                  </h1>
                  <div className="flex flex-col text-white">
                    <span>Kategori 1</span>
                    <span>Kategori 2</span>
                    <span>Kategori 3</span>
                  </div>
                </div>
                <div className="">
                  <h1
                    className={` text-3xl`}
                    style={{ color: data?.footer?.textColor }}
                  >
                    İletişim
                  </h1>
                  <div className="flex flex-col text-white">
                    <span>Bilgi 1</span>
                    <span>Bilgi 2</span>
                    <span>Bilgi 3</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden">
              <img
                src="/images/footer.png"
                alt="homepage image"
                className="w-full h-64 object-contain"
              />
            </div>

            <div className="max-sm:px-5 sm:px-20 mt-20 flex flex-col gap-5">
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg text-xl font-semibold">
                  Footer Kısmı İçin Arkaplan Renk Seçimi:{" "}
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  mode={"single"}
                  value={data?.footer?.backgroundColor}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      footer: {
                        ...prevValue.footer,
                        backgroundColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
              <div className="flex gap-5 items-center">
                <span className="max-sm:text-lg text-xl font-semibold">
                  Footer Renkli Yazı Kısmı İçin Renk Seçimi:
                </span>
                <ColorPicker
                  format="hex"
                  disabledFormat={true}
                  mode={"single"}
                  value={data?.footer?.textColor}
                  onChange={(color) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      footer: {
                        ...prevValue.footer,
                        textColor: color.toHexString(),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {step > 0 && (
        <div className="absolute bottom-10 left-32">
          <div className="w-20 h-20">
            <Button
              text={"Geri"}
              onClick={() => setStep((prev) => prev !== 0 && step - 1)}
            />
          </div>
        </div>
      )}
      {step < 2 && (
        <div className="absolute bottom-10 right-10">
          <div className="w-20 h-20">
            <Button
              text={`${step === 2 ? "Kaydet" : "Sıradaki"}`}
              onClick={() => setStep((prev) => (prev < 2 ? prev + 1 : 2))}
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="absolute bottom-10 right-10">
          <div className="w-20 h-20">
            <Button text={`Kaydet`} onClick={submitHandle} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateTheme;
