import React, { useEffect, useState } from "react";
import { VictoryPie } from "victory";
import { FaUserPlus, FaUsers, FaUtensils } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import { BsBoxSeam, BsFillBagPlusFill, BsQrCode } from "react-icons/bs";
import { FaHamburger, FaRegUserCircle } from "react-icons/fa";
import { PiFolderOpenLight } from "react-icons/pi";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { TbCategory, TbCategoryPlus, TbUsersGroup } from "react-icons/tb";
import { TiShoppingCart } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const Panel = () => {
  const [activeUrl, setActiveUrl] = useState("");
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const data = [
    { x: "Ürün 1", y: 30 },
    { x: "Ürün 2", y: 50 },
    { x: "Ürün 3", y: 23 },
    { x: "Ürün 5", y: 23 },
  ];

  const items = [
    {
      text: true,
      title: "ÜRÜNLER",
    },
    {
      text: false,
      title: "Tüm Ürünler",
      icon: FaHamburger,
      url: "/panel",
    },
    {
      text: false,
      title: "Ürün Ekle",
      icon: BsFillBagPlusFill,
      url: "",
    },
    {
      text: true,
      title: "KATEGORİLER",
    },
    {
      text: false,
      title: "Tüm Kategoriler",
      icon: TbCategory,
      url: "",
    },
    {
      text: false,
      title: "Kategori Ekle",
      icon: TbCategoryPlus,
      url: "",
    },
    {
      text: true,
      title: "KULLANICILAR",
    },
    {
      text: false,
      title: "Tüm Kullanıcılar",
      icon: FaUsers,
      url: "",
    },
    {
      text: false,
      title: "Kullanıcı Ekle",
      icon: FaUserPlus,
      url: "",
    },
  ];

  const routeHandler = (path) => navigate(path);

  useEffect(() => {
    setActiveUrl(pathname);
  }, [pathname]);

  return (
    <div className="flex w-full min-h-screen bg-gray-200 mt-16 gap-10 pe-10 max-lg:px-10">
      <aside className="-translate-x-full fixed top-0 left-0 w-64 h-full transition-transform duration-200 ease-in-out lg:translate-x-0 lg:relative bg-white min-h-screen">
        <div className="flex flex-col h-full bg-white border-r">
          <div className="text-center mt-4">
            <h1 className="font-bold text-xl">E-Waiter</h1>
          </div>
          <div className="">
            {items.map((item, index) => (
              <div className="">
                {item.text ? (
                  <div className="flex items-center gap-1 mt-5 px-5">
                    <p className="text-gray-400">{item.title}</p>
                    <div className="h-[1px] bg-gray-300 w-full"></div>
                  </div>
                ) : (
                  <div
                    className={`flex items-center px-3 py-2.5 mb-1 rounded-lg text-sm font-medium transition-all gap-2 ms-2 cursor-pointer`}
                    onClick={() => routeHandler()}
                  >
                    <item.icon
                      size={20}
                      color={`${
                        item?.url === activeUrl ? "#2563eb" : "#4b5563"
                      }`}
                    />
                    <h1
                      className={`text-sm font-medium transition-all ${
                        item?.url === activeUrl
                          ? "text-blue-600"
                          : "text-gray-600 hover:text-gray-900"
                      }`}
                    >
                      {item.title}
                    </h1>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </aside>
      <div className="flex-1 flex flex-col">
        <h1 className="font-bold text-3xl mb-5 pt-5">Dashboard</h1>
        {/* FIRST-DASHBOARD-ITEMS */}
        <div className="grid max-md:grid-cols-1 md:grid-cols-4 gap-5">
          <div className="bg-white p-5 rounded-md shadow-md">
            <div className="flex justify-between items-center">
              <div className="bg-[#F3F4F6] p-3 rounded-md ">
                <TiShoppingCart size={24} color="#3b82f6" />
              </div>
              <div className="flex items-center gap-1">
                <span className="text-green-600">+12.5%</span>
                <GoArrowUpRight size={20} color="#16a34a" />
              </div>
            </div>
            <div className="flex flex-col mt-10">
              <span className="mt-4 text-sm font-medium text-gray-600">
                Toplam Sipariş
              </span>
              <span className="mt-1 text-2xl font-semibold text-gray-900">
                1985
              </span>
            </div>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md">
            <div className="flex justify-between items-center">
              <div className="bg-[#F3F4F6] p-3 rounded-md">
                <AiOutlineFolderOpen size={24} color="#22c55e" />
              </div>
              <div className="flex items-center gap-1">
                <span className="text-green-600">+12.5%</span>
                <GoArrowUpRight size={20} color="#16a34a" />
              </div>
            </div>
            <div className="flex flex-col mt-10">
              <span className="mt-4 text-sm font-medium text-gray-600">
                Toplam Kategori
              </span>
              <span className="mt-1 text-2xl font-semibold text-gray-900">
                20
              </span>
            </div>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md">
            <div className="flex justify-between items-center ">
              <div className="bg-[#F3F4F6] p-3 rounded-md">
                <FaUtensils size={24} color="#ef4444" />
              </div>
              <div className="flex items-center gap-1">
                <span className="text-green-600">+12.5%</span>
                <GoArrowUpRight size={20} color="#16a34a" />
              </div>
            </div>
            <div className="flex flex-col mt-10">
              <span className="mt-4 text-sm font-medium text-gray-600">
                Toplam Ürün
              </span>
              <span className="mt-1 text-2xl font-semibold text-gray-900">
                150
              </span>
            </div>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md">
            <div className="flex justify-between items-center">
              <div className="bg-[#F3F4F6] p-3 rounded-md ">
                <TbUsersGroup size={24} color="#a855f7" />
              </div>
              {/* <div className="flex items-center gap-1">
                <span className="text-green-600">+12.5%</span>
                <TbUsersGroup size={20} color="#16a34a" />
              </div> */}
            </div>
            <div className="flex flex-col mt-10">
              <span className="mt-4 text-sm font-medium text-gray-600">
                Kayıtlı Üye
              </span>
              <span className="mt-1 text-2xl font-semibold text-gray-900">
                4
              </span>
            </div>
          </div>
        </div>
        {/* CHARTS */}
        <div className=" w-full my-10">
          <div className="bg-white w-full h-96 rounded-md shadow-md p-5">
            <h1 className="text-lg font-semibold mb-4">
              En Çok Görüntülenen Ürünler
            </h1>
            <VictoryPie
              data={data}
              colorScale={["#FF6384", "#36A2EB", "#FFCE56"]}
              radius={100}
              animate={{ duration: 1000 }}
            />
          </div>
        </div>
        {/* SECOND-DASHBOARD-ITEMS */}
        <div className="pb-10 grid max-md:grid-cols-1 md:grid-cols-4 gap-5">
          <div className="bg-white p-5 rounded-md shadow-md flex justify-center items-center flex-col gap-3 group transition-all">
            <div className="bg-blue-100 p-3 rounded">
              <BsBoxSeam
                className="group-hover:w-7 group-hover:h-7 transition-all"
                size={24}
                color="#2563EB"
              />
            </div>
            <h1 className="text-sm font-medium text-gray-600">Ürünler</h1>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md flex justify-center items-center flex-col gap-3 group transition-all">
            <div className="bg-orange-100 p-3 rounded">
              <PiFolderOpenLight
                className="group-hover:w-7 group-hover:h-7 transition-all"
                size={24}
                color="#EA580C"
              />
            </div>
            <h1 className="text-sm font-medium text-gray-600">Kategoriler</h1>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md flex justify-center items-center flex-col gap-3 group transition-all">
            <div className="bg-purple-100 p-3 rounded">
              <FaRegUserCircle
                className="group-hover:w-7 group-hover:h-7 transition-all"
                size={24}
                color="#9333EA"
              />
            </div>
            <h1 className="text-sm font-medium text-gray-600">Üyeler</h1>
          </div>
          <div className="bg-white p-5 rounded-md shadow-md flex justify-center items-center flex-col gap-3 group transition-all">
            <div className="bg-green-100 p-3 rounded">
              <BsQrCode
                className="group-hover:w-7 group-hover:h-7 transition-all"
                size={24}
                color="#16A34A"
              />
            </div>
            <h1 className="text-sm font-medium text-gray-600">Qr</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
