import React, { useEffect } from "react";
import MetaData from "../layouts/MetaData";
import { useState } from "react";
import { useUpdateLogoMutation } from "../../redux/api/businessApi";
import { toast } from "react-toastify";

const UpdateLogo = ({ business }) => {
  const [previewImage, setPreviewImage] = useState("/images/logo.png");
  const [update, { isLoading, error, isSuccess }] = useUpdateLogoMutation();

  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    update({ id: business._id, image: previewImage });
  };

  useEffect(() => {
    if (business?.logo?.url) {
      setPreviewImage(business.logo.url);
    }

    if (error) {
      toast.error(error.data.message);
    }
    if (isSuccess) {
      toast.success("Logo Güncellendi");
    }
  }, [business, error, isSuccess]);

  return (
    <div className="bg-white mx-3 rounded-md">
      <MetaData text={"Logo Güncelle"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5 border-b shadow-sm pb-4">
        Logo Güncelle
      </h1>
      <div className="px-10  pb-2">
        <div className="flex justify-center max-h-64">
          <img
            src={previewImage}
            alt="business-logo"
            className="object-cover"
          />
        </div>

        <div className="mt-5">
          <div className="border p-2 rounded-md shadow ">
            <input
              type="file"
              name="image"
              className=""
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="flex justify-end mt-5">
            <button
              type="submit"
              className="bg-orange-500 text-white py-3 px-10 rounded-lg"
              isLoading={isLoading}
              onClick={onSubmit}
            >
              {isLoading ? "Güncelleniyor..." : "Güncelle"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLogo;
